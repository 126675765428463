import * as _ from 'lodash'
import { FIELDS, ROLE_FORM } from '../../../constants/roles'
import { GFPP, GFPP_ACTIONS, GFPP_IDS } from './manifest-commons'
import translations from '../../../utils/translations'
import { roleDesignMapping } from './global-design-manifest'

const manifest = ({
  keepMainAction,
  keepMainAction2 = false,
  keepSettingsIcon,
  keepConnectIcon = false,
  registration = false,
  duplicatable = false,
}) => {
  const fieldSettingsAction = {
    actionId: GFPP_IDS.FIELD_SETTINGS,
    label: translations.t('fieldSettings.gfppTitle'),
  }

  const desktopMainActions = {
    mainAction1: keepMainAction ? GFPP.KEEP_DEFAULT : GFPP.REMOVE,
    mainAction2: keepMainAction2
      ? GFPP.KEEP_DEFAULT
      : keepConnectIcon
      ? GFPP.REMOVE
      : fieldSettingsAction,
  }
  const res: any = {
    relatedToRole: ROLE_FORM,
    displayName: translations.t('fields.labelName'),
    behavior: { duplicatable },
    gfpp: {
      desktop: {
        ...desktopMainActions,
        iconButtons: {
          [GFPP_ACTIONS.SETTINGS]: keepSettingsIcon ? GFPP.KEEP_DEFAULT : GFPP.REMOVE,
          [GFPP_ACTIONS.LAYOUT]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
          [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
          [GFPP_ACTIONS.CUSTOM_CONNECT]: keepConnectIcon && {
            actionId: GFPP_IDS.FIELD_SETTINGS,
          },
        },
        helpId: registration ? GFPP.HELP_ID.REGISTRATION_FIELD : GFPP.HELP_ID.FIELD,
      },
      mobile: {
        /* use default configurations */
        helpId: GFPP.HELP_ID.FIELD_MOBILE,
      },
    },
  }
  return res
}

export const createFieldsManifests = ({ connectFieldGfpp, shouldEnableCopyPasteFields }) => {
  const rolesWithoutDesignMapping = {
    [FIELDS.ROLE_FIELD_TEXT]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_TEXT_AREA]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_SELECT]: {
      ...manifest({
        keepMainAction: true,
        keepMainAction2: connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        keepSettingsIcon: !connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_RADIO_LIST]: {
      ...manifest({
        keepMainAction: true,
        keepMainAction2: connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        keepSettingsIcon: !connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_CHECKBOX]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_CHECKBOX_GROUP]: {
      ...manifest({
        keepMainAction: true,
        keepMainAction2: connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        keepSettingsIcon: !connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_AGREE_TERMS]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
      }),
    },
    [FIELDS.ROLE_FIELD_SUBSCRIBE]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
      }),
    },
    [FIELDS.ROLE_FIELD_DATE]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: !connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_FILE_UPLOAD]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: !connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_RATING]: {
      ...manifest({
        keepMainAction: true,
        keepSettingsIcon: !connectFieldGfpp,
        keepConnectIcon: connectFieldGfpp,
        duplicatable: shouldEnableCopyPasteFields,
      }),
    },
    [FIELDS.ROLE_FIELD_RECAPTCHA]: {
      relatedToRole: ROLE_FORM,
      displayName: translations.t('fields.labelName'),
      behavior: { duplicatable: false },
      gfpp: {
        desktop: {
          mainAction1: GFPP.REMOVE,
          mainAction2: {
            actionId: GFPP_IDS.RECAPTCHA_HELP,
            label: translations.t('manifest.captcha.learnMore'),
          },
          iconButtons: {
            [GFPP_ACTIONS.SETTINGS]: GFPP.REMOVE,
            [GFPP_ACTIONS.LAYOUT]: GFPP.REMOVE,
            [GFPP_ACTIONS.ANIMATION]: GFPP.KEEP_DEFAULT,
            [GFPP_ACTIONS.CONNECT]: GFPP.REMOVE,
          },
          helpId: GFPP.HELP_ID.CAPTCHA,
        },
        mobile: {
          helpId: GFPP.HELP_ID.CAPTCHA,
        },
      },
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LOGIN_EMAIL]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PASSWORD]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_AGREE_TERMS]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CHECKBOX_JOIN_COMMUNITY]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_CODE_OF_CONDUCT]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_PRIVACY_POLICY]: {
      ...manifest({
        keepMainAction: connectFieldGfpp,
        keepSettingsIcon: false,
        keepConnectIcon: connectFieldGfpp,
        registration: true,
      }),
    },
    [FIELDS.ROLE_FIELD_REGISTRATION_FORM_LINK_TO_LOGIN_DIALOG]: {
      relatedToRole: ROLE_FORM,
      behavior: { duplicatable: false },
    },
  }
  return _.reduce(
    rolesWithoutDesignMapping,
    (acc, manifest, role) => {
      acc[role] = { ...manifest, designMapping: roleDesignMapping[role] }
      return acc
    },
    {}
  )
}
